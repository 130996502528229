import React from 'react';
import { createRoot } from 'react-dom/client';
import { Api, WEB, FORM_FACTOR_DESKTOP, registerServiceWorker } from 'renative';
import { initializeState } from './src/DeviceUtils';
import 'react-native-gesture-handler';

Api.platform = WEB;
Api.formFactor = FORM_FACTOR_DESKTOP;

// add global style for MS-Edge compatibility
const headEl = document.getElementsByTagName('head')[0];
const styleEl = document.createElement('style');
const styleText = document.createTextNode(`
    input[type=password]::-ms-reveal,
    input[type=password]::-ms-clear
    {
        display: none;
    }
`);
styleEl.type = 'text/css';
styleEl.id = 'ms-edge-password-eye-icon-hide';
styleEl.appendChild(styleText);
headEl.appendChild(styleEl);

const a = async () => {
    try {
        await initializeState();
        const App = await import('./src/app.web');
        const container = document.getElementById('root');
        const root = createRoot(container);
        root.render(React.createElement(App.default));
        registerServiceWorker();
    } catch (e) {
        console.error(e);
    }
};

a();
